var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-subtitle',[_vm._v(" Proveedor ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.reglas.documento,"label":"Documento *","required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.documento),callback:function ($$v) {_vm.$set(_vm.datos, "documento", $$v)},expression:"datos.documento"}}),_c('v-text-field',{staticClass:"mt-2",attrs:{"rules":_vm.reglas.requerido,"label":"Nombre *","required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.nombre),callback:function ($$v) {_vm.$set(_vm.datos, "nombre", $$v)},expression:"datos.nombre"}}),_c('v-textarea',{staticClass:"mt-2",attrs:{"label":"Descripcion *","rules":_vm.reglas.requerido,"required":"","outlined":"","dense":"","hide-details":"auto","rows":"3"},model:{value:(_vm.datos.descripcion),callback:function ($$v) {_vm.$set(_vm.datos, "descripcion", $$v)},expression:"datos.descripcion"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":'Telefono',"properties":{
                  outlined: true,
                  clearable: false,
                  dense: true,
                  rules: _vm.reglas.telefono,
                  hideDetails: 'auto',
                },"options":{
                  inputMask: '(####) ###-####',
                  outputMask: '(####) ###-####',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: true,
                }},model:{value:(_vm.datos.telefono),callback:function ($$v) {_vm.$set(_vm.datos, "telefono", $$v)},expression:"datos.telefono"}}),_c('v-text-field',{staticClass:"mt-2",attrs:{"rules":_vm.reglas.correo,"label":"Correo","required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.correo),callback:function ($$v) {_vm.$set(_vm.datos, "correo", $$v)},expression:"datos.correo"}}),_c('v-textarea',{staticClass:"mt-2",attrs:{"label":"Direccion","required":"","outlined":"","dense":"","hide-details":"auto","rows":"3"},model:{value:(_vm.datos.direccion),callback:function ($$v) {_vm.$set(_vm.datos, "direccion", $$v)},expression:"datos.direccion"}})],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.limpiar()}}},[_vm._v(" Limpiar ")]),_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.Guardar()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }