


//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../../..";
import router from '@/router'


class ProveedorServices {

  proveedorListarBusqueda(params) {
    return http.get("/inventario/proveedor/proveedorListarBusqueda", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }


  proveedorListar(params) {
    return http.get("/inventario/proveedor/proveedorListar", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
        }
      });
  }


 
  nuevoProveedor(data) {
    return http.post("/inventario/proveedor/nuevoProveedor", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
 
}

export default new ProveedorServices();