<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Proveedor </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  :rules="reglas.documento"
                  v-model="datos.documento"
                  label="Documento *"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  class="mt-2"
                  :rules="reglas.requerido"
                  v-model="datos.nombre"
                  label="Nombre *"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>

                <v-textarea
                  class="mt-2"
                  label="Descripcion *"
                  v-model="datos.descripcion"
                  :rules="reglas.requerido"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field-simplemask
                  v-model="datos.telefono"
                  v-bind:label="'Telefono'"
                  v-bind:properties="{
                    outlined: true,
                    clearable: false,
                    dense: true,
                    rules: reglas.telefono,
                    hideDetails: 'auto',
                  }"
                  v-bind:options="{
                    inputMask: '(####) ###-####',
                    outputMask: '(####) ###-####',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: true,
                  }"
                />
                <v-text-field
                  class="mt-2"
                  :rules="reglas.correo"
                  v-model="datos.correo"
                  label="Correo"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>

                <v-textarea
                  class="mt-2"
                  label="Direccion"
                  v-model="datos.direccion"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import ProveedorServices from '@/api/servicios/inventario/maestro/ProveedorServices'
export default {
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      documento: [
        v => !!v || 'documento es requerido',
        v => /^([VGEJvgej][\-]\d{5,9}[\-]\d{1})$/.test(v) || 'El Rif debe ser valido: ej J-12345678-0',
      ],
      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'Telefono invalido, Ej: (0414) 000 00 00'
            : true,
      ],
      correo: [
        v =>
          !!v
            ? /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) || 'Correo Invalido, Ej: usuario@gmail.com'
            : true,
      ],
    }
    const datosInit = {
      id: -1,
      documento: '',
      nombre: '',
      descripcion: '',
      telefono: '',
      correo: '',
      direccion: '',
      id_puesto: null,
    }
    const datos = ref(JSON.parse(JSON.stringify({...datosInit , id_puesto : store.state.puestoSelect.id_puesto})))

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify({...datosInit , id_puesto : store.state.puestoSelect.id_puesto}))
      form.value.resetValidation()
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = item
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          ProveedorServices.nuevoProveedor({ ...datos.value })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == "GUARDADO_CON_EXITO") {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: `,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) { 
          store.commit('setAlert', {
            message: error,
            type: 'error',
                  error: error,
                  funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
    }
  },
}
</script>